<template>
  <div class="container">
    <div class="top">
      <el-input v-model="input" placeholder="请输入关键词进行搜索"></el-input>
      <el-button type="primary" icon="el-icon-search">搜索</el-button>
      <el-select v-model="user_type" placeholder="请选择国家">
        <el-option label="管理员" value="1"></el-option>
        <el-option label="客户" value="2"></el-option>
        <el-option label="终端用户" value="3"></el-option>
      </el-select>
      <el-select v-model="user_type" placeholder="请选择城市">
        <el-option label="管理员" value="1"></el-option>
        <el-option label="客户" value="2"></el-option>
        <el-option label="终端用户" value="3"></el-option>
      </el-select>
      <el-button
        type="primary"
        icon="el-icon-plus"
        class="add-username"
        @click="add"
        >添加客户</el-button
      >
    </div>
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      :max-height="$store.state.clientHeight"
    >
      <el-table-column prop="id" label="序号" width="120"> </el-table-column>
      <el-table-column prop="username" label="客户名称" width="250">
      </el-table-column>
      <el-table-column prop="Email" label="终端用户名称"> </el-table-column>
      <el-table-column prop="usernametype" label="公司名称"> </el-table-column>
      <el-table-column prop="corporateName" label="公司地址"> </el-table-column>
      <el-table-column prop="creatTime" label="公司邮箱"> </el-table-column>
      <el-table-column prop="creatTime" label="国家/城市"> </el-table-column>
      <el-table-column fixed="right" label="设置" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small">编辑</el-button>
          <el-button type="text" size="small" @click="del(scope)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="footer"
      background
      layout="prev, pager, next, jumper"
      :total="1000"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      router: false,
      input: "",
      user_type: "",
      tableData: [
        {
          id: "1",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "2",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "3",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "4",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "5",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "6",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "7",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "8",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "9",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "10",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "11",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "12",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "13",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "14",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
        {
          id: "15",
          username: "q981115394",
          Email: "981115394@qq.com",
          usernametype: "管理员",
          corporateName: "公司名称",
          creatTime: "2020/12/29",
        },
      ],
    };
  },
  methods: {
    // 添加账号
    add() {
      this.$router.push("/Home/EndUserList/AddEndUserList");
    },
    // 删除指定账号
    del(e) {
      this.$confirm(
        this.fun.getLanguageText(
          this.$store.state.language,
          "此操作将永久删除该文件, 是否继续?"
        ),
        this.fun.getLanguageText(this.$store.state.language, "提示"),
        {
          confirmButtonText: this.fun.getLanguageText(
            this.$store.state.language,
            "确定"
          ),
          cancelButtonText: this.fun.getLanguageText(
            this.$store.state.language,
            "取消"
          ),
          type: "warning",
        }
      )
        .then(() => {
          // 执行删除操作
          this.tableData.splice(e.$index, 1);
          this.$message({
            type: "success",
            message: this.fun.getLanguageText(
              this.$store.state.language,
              "删除成功!"
            ),
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.fun.getLanguageText(
              this.$store.state.language,
              "已取消删除"
            ),
          });
        });
    },
    // 计算高度
    getListHeight() {
      let Html = document.getElementsByClassName("box-card");
      if (Html.length > 0) {
        this.$store.state.clientHeight = Html[0].clientHeight - 131 - 60;
      }
    },
  },
  mounted() {
    this.getListHeight();
    window.onresize = () => {
      return (() => {
        this.getListHeight();
      })();
    };
  },
  destroyed() {
    window.onresize = null;
  },
};
</script>

<style lang="less" scoped>
.el-input {
  width: 240px;
  margin-right: 10px;
}

.el-select {
  width: 180px;
  margin-left: 20px;
}

.container {
  display: flex;
  flex-direction: column;
}

.top {
  // height: 32px;
  margin-bottom: 20px;
  position: relative;
}

.add-username {
  position: absolute;
  right: 0;
}

.footer {
  display: flex;
  padding: 0;
  margin-top: 14px;
  justify-content: flex-end;
}
</style>
